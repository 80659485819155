import { Component, enableProdMode, OnInit } from '@angular/core';
import { GlobalService } from './global/global.service';

import { Router, NavigationEnd
    ,ActivatedRoute } from "@angular/router";
import { filter 
    , map, mergeMap } from 'rxjs/operators';

import { GoogleAnalyticServiceService } from './_helpers/google-analytic-service.service';
import * as uuid from 'uuid';
import { IRouteSettings } from './_helpers/iroute-settings'; 
import { CookieService } from 'ngx-cookie-service'; 

enableProdMode();

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'aerochubb';
    private currentRouteData: IRouteSettings;   
    tagManagerSessionId: any; 
    constructor(public globalService: GlobalService,
        private router: Router
        ,private activatedRoute: ActivatedRoute,
         private cookieService: CookieService,
         private googleAnalyticService: GoogleAnalyticServiceService) {
        this.globalService.LF_showLoader(false);
    }


    // Function to be called on each page redirection
    onActivate(event) {
        this.globalService.LF_showLoader(false);
        // to move to top on every page load
        window.scroll(0, 0);
    }


    ngOnInit() {
        // check if the cookie exist, if not then create a new one with a uuid value
         if (!this.cookieService.get('chubbTagManager')) {
       this.cookieService.set('chubbTagManager', uuid.v4(),730); //el número de días de su preferencia para la expiración
        }   
 
//assign the value of the cookie to the tag manager session id
      this.tagManagerSessionId = this.cookieService.get('chubbTagManager'); 
 
 //call function to get the current route
      this.getCurrentRouteData();
    } 


    private getCurrentRouteData() {
        this.router.events
          .pipe(
            filter((e) => e instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
              while (route.firstChild) {
                route = route.firstChild;
              }
              return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
          )
          .subscribe((data: any) => {
            this.currentRouteData = data;
      this.googleAnalyticService.googleTaskManagerPush({
              event: 'appScreenView',
              screenPath: data.path,
              screenName: data.title,
              //sessionID: this.tagManagerSessionId
            });
          });
      } 
   

}
