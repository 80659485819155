import {Inject, Injectable} from '@angular/core';
import {GOOGLE_TASK_MANAGER_DATA_LAYER, IGoogleTaskManagerDataLayer} from '../providers/google-task-manager.provider';
import {environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticServiceService {
  constructor(@Inject(GOOGLE_TASK_MANAGER_DATA_LAYER) private googleTaskManagerDataLayer: IGoogleTaskManagerDataLayer) {
  }
  /**
   *
   * @param data
   */
  googleTaskManagerPush(data: object) {
    if (environment.gtmTrackingId) {
      this.googleTaskManagerDataLayer.push(data);
    }
  }
}