import { Http, Headers } from '@angular/http';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from "@angular/router";
declare var $: any;

@Injectable({
	providedIn: 'root'
})
export class GlobalService {
	public user: any;
	public showApiLoader: boolean = false;
	constructor(public httpClient: Http, public toastr: ToastrManager, public router: Router) {
		
	}
	// Rest services starts
	// Function to patch data to API
	RF_patchData(data, authToken: string) {
		let headers = new Headers();
		if (authToken != '')
			headers.append('Authorization', authToken);
		return new Promise(resolve => {
			this.httpClient.patch(data.URL, data.DATA, {
				headers: headers
			}).subscribe(data => {
				resolve(data);
			}, err => {
				// this.LF_redirectOnError(err);
				resolve(err);
			});
		});
	}
	// Function to get data from API
	RF_getData(url, authToken: string) {
		let headers = new Headers();
		if (authToken != '')
			headers.append('Authorization', authToken);
		return new Promise((resolve, reject) => {
			this.httpClient.get(url, {
				headers: headers
			}).subscribe(res => {
				resolve(res);
			}, (err) => {
				// this.LF_redirectOnError(err);
				reject(err);
			});
		});
	}
	// Function to post data to API
	RF_postData(data, authToken: string) {
		let headers = new Headers();
		if (authToken != '')
			headers.append('Authorization', authToken);
		return new Promise(resolve => {
			this.httpClient.post(data.URL, data.DATA, {
				headers: headers
			}).subscribe(data => {
				resolve(data);
			}, err => {
				// this.LF_redirectOnError(err);
				resolve(err);
			});
		});
	}
	// Function to redirect the user to home page on 401 and other errors
	LF_redirectOnError(err) {
		
	}
	// Rest services ends

	// Global functions starts
	LF_setUser(data: any) {
		this.user = data;
		localStorage.setItem("user", this.user);
	}
	LF_getUser() {
		this.user = localStorage.getItem("user");
	}
	// Function to show error message
	LF_showWarningMessage(message, title) {
		this.toastr.warningToastr(message, title);
	}

	// Function to show error message
	LF_showErrorMessage(message, title) {
		this.toastr.errorToastr(message, title);
	}

	// Function to show success message
	LF_showSuccessMessage(message, title) {
		this.toastr.successToastr(message, title);
    }
    
    // Function to redirect internal pages
    LF_redirectToPage(page) {
        this.router.navigate([page]);
    }

    //Function to redirect from select
    LF_selectRedirectToPage(event){
        console.log($(event));
        console.log($(event).attr('data-slug'));
        console.log($(event).data('slug'));
    }
    //Function to Initialize own - events
    public LF_owlSingle() {
        if($('.owl-single').length) {
            $('.owl-single').each(function(index, el) {
                var $this = $(this);
                $this.owlCarousel({
                    autoPlay: 4000,
                    autoplayHoverPause: true,
                    singleItem: true,
                    smartSpeed: 1000,
                    navigation:true,
                    navigationText: ['<i class="hillter-icon-left-arrow"></i>','<i class="hillter-icon-right-arrow"></i>']
                });
            });
        }
    }

    //Preloader
    public LF_showLoader(action){
        if(action)
        {                    
            $('#preloader').delay(1000).fadeIn('400', function() {
                $(this).fadeIn();
            });
        }
        else{
            $('#preloader').delay(1000).fadeOut('400', function() {
                $(this).fadeOut();
            });
        }
    }
    // Global functions ends
}
