import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

var HostAba = "abaqa.chubb.com/aerochubb";

const routes: Routes = [
    {
        path: "", 
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {       
            title : 'dashboard',
            path: '/dashboard'
              } 
    },
    {
        path: "promocion", 
        loadChildren: () => import('./promocion/promocion.module').then(m => m.PromocionModule),
        data: {       
            title : 'promocion',
            path: '/promocion'
              } 
    },
    {
        path: "participa", 
        loadChildren: () => import('./participa/participa.module').then(m => m.ParticipaModule),
        data: {       
            title : 'participa',
            path: '/promocion/participa'
              } 
    },
    {
        path: "eventos-culturales", 
        loadChildren: () => import('./eventos-culturales/eventos-culturales.module').then(m => m.EventosCulturalesModule),
        data: {       
            title : 'eventos-culturales',
            path: '/eventos-culturales'
              } 
    },
    {
        path: "eventos-culturales/:id", 
        loadChildren: () => import('./eventos-culturales-detalle/eventos-culturales-detalle.module').then(m => m.EventosCulturalesDetalleModule),
        data: {       
            title : 'eventos-culturales-detalle',
            path: '/eventos-culturales-detalle'
              }
    },
    {
        path: "login", 
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
        data: {       
            title : 'login',
            path: '/login'
              }
    },
    {
        path: "beneficios", 
        loadChildren: () => import('./cupones/cupones.module').then(m => m.CuponesModule),
        data: {       
            title : 'beneficios',
            path: '/beneficios'
              }
    },
    {
        path: "concierge", 
        loadChildren: () => import('./concierge/concierge.module').then(m => m.ConciergeModule),
        data: {       
            title : 'concierge',
            path: '/concierge'
              }
    },
    {
        path: "contactanos", 
        loadChildren: () => import('./contactanos/contactanos.module').then(m => m.ContactanosModule),
        data: {       
            title : 'contactanos',
            path: '/contactanos'
              }
    },
    {
        path: "guias-turisticas", 
        loadChildren: () => import('./guias-turisticas/guias-turisticas.module').then(m => m.GuiasTuristicasModule),
        data: {       
            title : 'guias-turisticas',
            path: '/guias-turisticas'
              }

    },
    {
        path: "guias-turistica/:id", 
        loadChildren: () => import('./guias-turistica-detalle/guias-turistica-detalle.module').then(m => m.GuiasTuristicaDetalleModule),
        data: {       
            title : 'guias-turistica-detalle',
            path: '/guias-turistica-detalle'
              }
    },
    {
        path: "seguro-de-viaje", 
        loadChildren: () => import('./seguro-de-viaje/seguro-de-viaje.module').then(m => m.SeguroDeViajeModule),
        data: {       
            title : 'seguro-de-viaje',
            path: '/seguro-de-viaje'
              }
    },
    {
        path: "discount-pass-nacional", 
        loadChildren: () => import('./discount-pass-nacional/discount-pass-nacional.module').then(m => m.DiscountPassNacionalModule),
        data: {       
            title : 'discount-pass-nacional',
            path: '/discount-pass-nacional'
              }
    },
    {
        path: "preguntas-frecuentes", 
        loadChildren: () => import('./preguntas-frecuentes/preguntas-frecuentes.module').then(m => m.PreguntasFrecuentesModule),
        data: {       
            title : 'preguntas-frecuentes',
            path: '/preguntas-frecuentes'
              }
    },
    {
        path: 'pagina-no-encontrada',
        loadChildren: () => import('./pagenotfound/pagenotfound.module').then(m => m.PagenotfoundModule),
        data: {       
            title : 'pagina-no-encontrada',
            path: 'pagina-no-encontrada'
              }
    },
    {
        path: '**',
        redirectTo: 'pagina-no-encontrada'
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
