import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpModule } from '@angular/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';


import { CRUX_WINDOW_PROVIDERS } from './providers/window.provider';
import { WINDOW_PROVIDERS } from './providers/window.service';
import { GoogleTaskManagerDataLayerProvider } from './providers/google-task-manager.provider';
import { CookieService } from 'ngx-cookie-service';
import { PromocionComponent } from './promocion/promocion.component';
import { ParticipaComponent } from './participa/participa.component'; 
import {Routes, RouterModule} from '@angular/router';
registerLocaleData(es);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
    ],
    providers: [{ provide: LOCALE_ID, useValue: 'es-MX' },
    GoogleTaskManagerDataLayerProvider, WINDOW_PROVIDERS, CRUX_WINDOW_PROVIDERS, CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
